import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import Link from "components/common/Link";
import Faq from 'components/common/Faq';
import { LinkModal } from "kh-common-components";

import ffc from "images/fremantle-dockers/FFC_Horiz_C-RGB.svg";
import fixtures from "images/fremantle-dockers/flowmoji-afl-footy-fixtures_light_RGB.svg";
import merch from "images/fremantle-dockers/flowmoji-FREO-merch_RGB.svg";
import tickets from "images/fremantle-dockers/flowmoji-FREO-tickets_light_RGB.svg";
import football from "images/fremantle-dockers/header-image_FFC_KH-football.png";

import rac from "images/rac/natural-gas/logo-rac.png";
import flybuys from "images/flybuys/logo-flybuys.png";
import cinemas from "images/telethon-community-cinemas/logo-communitycinemas.png";
import goodFood from "images/good-food-and-wine/gfws-hero-badge-2.png";

import faqData from "../../../constants/components/accordion-list/fremantle-dockers-faq.json"
import { modalContents } from "constants/modal-content";

import "styles/pages/fremantle-dockers.css";

const ffcDynamicsForm = `
    <div
        data-form-id='fe0db9bf-dde1-ef11-9342-000d3acbaa8f'
        data-form-api-url='https://public-oce.mkt.dynamics.com/api/v1.0/orgs/2a4e1f66-a480-ef11-ac1c-000d3ad17ec9/landingpageforms'
        data-cached-form-url='https://assets1-oce.mkt.dynamics.com/2a4e1f66-a480-ef11-ac1c-000d3ad17ec9/digitalassets/forms/fe0db9bf-dde1-ef11-9342-000d3acbaa8f' >
    </div>
    <script src = 'https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/oce/FormLoader/FormLoader.bundle.js' ></script>
    <script>document.documentElement.lang = navigator.language;</script>
`;

export default function() {
    return (
        <>
            <SEO
                title="Fremantle Dockers | Kleenheat sponsorship"
                titleBespoke="true"
                description="We've partnered with the Fremantle Dockers to deliver unforgettable experiences on and off the field with exclusive offers available for Kleenheat customers."
            />
            <Header />
            <LayoutMain>
                <LayoutPanel
                    background="mer-bg--primary-cyan"
                    padding="mer-py-lg"
                    theme="mer-theme--cyan"
                >
                    <div className="container ffc-hero-banner">
                        <div className="row mer-panel justify-content-center">
                            <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg">
                                <div className="mer-pb-lg">
                                    <img className="logo-ffc" src={ffc} alt="Fremantle Dockers and Kleenheat AFL Football" />
                                </div>
                                <section>
                                    <div className="content">
                                        <h2>A partnership made in WA</h2>
                                        <p>We're all for WA, and we know WA loves its footy.</p>
                                        <p>We've partnered with the Fremantle Dockers to deliver unforgettable experiences on and off the field.</p>
                                        <p>Kleenheat customers can access ticket offers, giveaways and discounted merchandise.</p>
                                        <div className="mer-panel__actions mer-button-group">
                                            <Link
                                                text="Ticket offer"
                                                link="#tickets"
                                                linkClass="mer-button mer-button--primary"
                                            />
                                            <Link
                                                text="Learn more"
                                                link="/blog/community/kleenheat-partners-with-fremantle-dockers"
                                                linkClass="mer-button mer-button--secondary"
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="col-8 col-sm-5 col-lg-5 mer-panel__images mt-3 mt-lg-0 p-lg-0 football">
                                <img src={football} className="mer-panel__image" alt="Fremantle Dockers and Kleenheat AFL Football" />
                            </div>
                        </div>
                    </div>
                </LayoutPanel>
                <LayoutPanel
                    background="mer-bg--ui-white"
                    padding="mer-py-lg"
                    margin="football-margin ffc-sub-hero-panel"
                    theme="mer-theme--light"
                    id="tickets"
                >
                    <div className="container">
                        <div className="row mer-panel align-items-center justify-content-center">
                            <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text--size-lg">
                                <div className="content text-center">
                                    <h2 className="mer-color--primary-cyan">Kleenheat customer benefits</h2>
                                </div>
                            </div>
                            <div className="col-10 col-lg-8">
                                <div className="row mer-panel mer-py-de">
                                    <div className="col-6 col-sm-4 mer-panel__images mb-0">
                                        <img className="mer-panel__image" src={tickets} alt="Fremantle Dockers Tickets" />
                                    </div>
                                    <div className="col-12 col-sm-7 offset-sm-1 content">
                                        <h4>2-for-1 Freo 3 Game Pass</h4>
                                        <p>Get two <a target="_blank" href="https://membership.fremantlefc.com.au/membership/3-game-pass">Freo 3 Game Passes</a> for the price of one.*</p>
                                        <p>Passes include access to three Freo Dockers home games at Optus Stadium.</p>
                                        <small className="mer-color--ui-dark">*Offer is valid for two adult 3 Game Passes per transaction.<br/>Excludes RAC Derby. <LinkModal
                                                                            modalContents={modalContents}
                                                                            useModal="true"
                                                                            title="Terms & Conditions"
                                                                            // linkClass=""
                                                                            displayContent="See full terms and conditions"
                                                                            modalTitle="Terms & Conditions"
                                                                            modalContentName="terms-2-for-1-freo-3-game-passes"
                                                                            okayButtonLabel="Close"
                                                                        />.</small>
                                        <div className="mer-panel__actions mer-button-group">
                                            <Link
                                                text="Redeem now"
                                                link="#redeem"
                                                linkClass="mer-button mer-button--primary"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10 col-lg-8">
                                <div className="row mer-panel mer-py-de">
                                    <div className="col-6 col-sm-4 mer-px-de mer-panel__images mb-0">
                                        <img className="mer-panel__image" src={merch} alt="Fremantle Football Club Merchandise" />
                                    </div>
                                    <div className="col-12 col-sm-7 offset-sm-1 content">
                                        <h4>10% off merchandise</h4>
                                        <p>Get 10% off Freo Dockers merchandise <a target="_blank" href="https://teamstore.fremantlefc.com.au">online</a> or in-store.</p>
                                        <p>Use the code <strong>KLEENHEAT10</strong> at the checkout.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>
                <LayoutPanel
                    background="mer-bg--ui-light"
                    padding="mer-py-lg"
                    theme="mer-theme--light"
                >
                    <div className="container">
                        <div className="row mer-panel align-items-center justify-content-center">
                            <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text--size-lg">
                                <div className="content text-center">
                                    <h2>Catch all the action</h2>
                                </div>
                            </div>
                            <div className="col-10 col-lg-8">
                                <div className="row mer-panel mer-py-de">
                                    <div className="col-6 col-sm-4 mer-panel__images mb-0">
                                        <img className="mer-panel__image" src={fixtures} alt="Fremantle Dockers Tickets" />
                                    </div>
                                    <div className="col-12 col-sm-7 offset-sm-1 content">
                                        <h4>Freo fixtures</h4>
                                        <p>Keen to catch a Freo game at Optus Stadium?</p>
                                        <p>View the <a target="_blank" href="https://www.fremantlefc.com.au/footy/fixture">fixtures</a> to see when and where Freo is playing this season.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>
                <LayoutPanel
                    background="mer-bg--ui-white"
                    padding="mer-py-lg"
                    theme="mer-theme--light"
                    id="redeem"
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-7 col-lg-5">
                                <div className="content text-center">
                                    <h2 className="mer-color--primary-cyan">Get your customer discount code</h2>
                                    <p>Enter the <strong>email address used on your Kleenheat account</strong> and we'll send your customer benefit code straight to your inbox.</p>
                                </div>
                            </div>
                            <div className="col-10 col-sm-12 col-lg-8" >
                                <div className="row align-items-center justify-content-center ffc-dynamics-form" dangerouslySetInnerHTML={{__html: ffcDynamicsForm}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>
                <Faq panelBackground="mer-bg--ui-light"
                    panelPadding="mer-py-lg"
                    data={faqData}
                    id="faq"
                />
                <LayoutPanel
                    background="mer-bg--ui-white"
                    padding="mer-py-lg"
                    theme="mer-theme--light"
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-7 col-lg-7">
                                <div className="content text-center">
                                    <h2 className="mer-color--primary-cyan">Our other partnerships and sponsorships</h2>
                                </div>
                            </div>
                            <div className="col-10 col-sm-12 col-lg-8">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-5 col-sm-3 mer-p-de">
                                        <a href="/rac" title="Open Kleenheat RAC page" className="mer-link--hover-none">
                                            <img className="mer-panel__image" src={rac} alt="RAC logo" />
                                        </a>
                                    </div>
                                    <div className="col-5 col-sm-3 mer-p-de">
                                        <a href="/flybuys" title="Open Kleenheat Flybuys page" className="mer-link--hover-none">
                                            <img className="mer-panel__image" src={flybuys} alt="flybuys logo" />
                                        </a>
                                    </div>
                                    <div className="col-5 col-sm-3 mer-p-de">
                                        <a href="/about/community/telethon-community-cinemas" title="Open Telethon Community Cinemas page" className="mer-link--hover-none">
                                            <img className="mer-panel__image" src={cinemas} alt="Telethon Community Cinemas" />
                                        </a>
                                    </div>
                                    <div className="col-5 col-sm-3 mer-p-de">
                                        <a href="/about/community/good-food-wine-show" title="Open Good Food Wine Show page" className="mer-link--hover-none">
                                            <img className="mer-panel__image" src={goodFood} alt="Good Food &amp; Wine Show" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>
            </LayoutMain>
            <Footer />
        </>
    )
}